.bg-green {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}
@font-face {
    font-family: Wantedo;
    src: url(./fonts/Wantedo.ttf);
}

body{
    font-family: Wantedo, sans-serif;
}