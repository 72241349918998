.swiper-button-next,
.swiper-button-prev {
    position: absolute;
    top: 50%;
    width: calc(var(--swiper-navigation-size)/ 44 * 27);
    height: var(--swiper-navigation-size);
    margin-top: calc(0px - (var(--swiper-navigation-size)/ 2));
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #14c1a3 !important;
}
.bannar-wrapper {
    height: 100vh;
    width: 100vw;
}

.glasseffect2{
    /* backdrop-filter: blur(5px) saturate(200%);
    -webkit-backdrop-filter: blur(5px) saturate(200%);
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125); */
    backdrop-filter: blur(20px) saturate(180%);
    -webkit-backdrop-filter: blur(20px) saturate(180%);
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);
}


.bannar-wrapper .navbar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999;
    transition: all 0.5s ease-in-out;
}

.bannar-wrapper .bannerVideo {
    width: 100vw;
    max-width: 100vw !important;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;

}

.bannar-wrapper .hero-content {
    height: 100%;
    position: absolute;
    display: flex;
    top: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.steps {
    overflow-x: hidden !important;
}

.steps .card {
    z-index: 999;
    transition: all 0.3s ease-in-out;
    width: 300px !important;
    min-height: 260px;
    min-width: 200px;
    border: 2px solid white;
}

.back{
    background: #EB5757;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, #000000, #EB5757);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #000000, #EB5757); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}