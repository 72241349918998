.bannar-wrapper {
    height: 100vh;
    width: 100vw;
}




.bannar-wrapper .navbar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999;
    transition: all 0.5s ease-in-out;
}

.bannar-wrapper .bannerVideo {
    width: 100vw;
    max-width: 100vw !important;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;

}

.bannar-wrapper .hero-content {
    height: 100%;
    position: relative;
    display: flex;
    top: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.centerr{
    justify-content: center;
    align-items: center;

}

.glasseffect{
    backdrop-filter: blur(5px) saturate(200%);
    -webkit-backdrop-filter: blur(5px) saturate(200%);
    background-color: rgba(74, 74, 74, 0.65);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);
}

