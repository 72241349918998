.card {
    backdrop-filter: blur(5px) saturate(180%);
    -webkit-backdrop-filter: blur(5px) saturate(180%);
    background-color: rgba(126, 118, 10, 0.7);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);
}

.questionCard{
    backdrop-filter: blur(5px) saturate(180%);
    -webkit-backdrop-filter: blur(5px) saturate(180%);
    background-color: rgba(126, 118, 10, 0.7);
}
.w-full{
    left: 191px;

}