@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,300;0,600;1,400&display=swap'); */

@font-face {
    font-family: Wantedo;
    src: url(../src/Assets/Wantedo.ttf);
}

@font-face {
    font-family: wanted;
    src: url(../src/Assets/Wantedo.ttf);
}

body{
    font-family: Wantedo, sans-serif;
}

:root {
    color-scheme: only light !important;
}

* {
    scroll-behavior: smooth !important;
}


body {
    /* font-family: 'IBM Plex Mono', monospace; */
    font-family: Wantedo, sans-serif;
    overflow-x: hidden !important;
}

.swal2-popup {
    color: white !important;
}

.swal2-confirm {
    background-color: #4c5a6c !important;
}




@media (prefers-color-scheme: dark) {
    .donationInput {
        color: white !important;
    }
}